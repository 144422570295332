<template>
  <div class="elite-tabs-wrapper-content">
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("COMMON.TAGS") }}
        </dt>
        <dd class="col-sm-8">
          <tags :tags="file.tags" @tagsUpdated="fileUpdated" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ file.name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          <div v-html="file.excerpt"></div>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="file.allowedLocations" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="file.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ file.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ file.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";

export default {
  name: "file-view-modal-global",

  props: ["file"],

  components: { Tags },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    fileUpdated() {
      this.$emit("fileUpdated", true);
    },
  },

  mounted() {},

  watch: {},
};
</script>
