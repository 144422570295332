<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ location.name }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ location.name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("LOCATIONS.LOCATION_CODE") }}</dt>
        <dd class="col-sm-8">
          {{ location.location_code ?? "-" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("LOCATIONS.IS_MUNICIPAL") }}</dt>
        <dd class="col-sm-8">
          <span v-if="location.is_municipal">
            {{ $t("COMMON.YES") }}
          </span>
          <span v-else>
            {{ $t("COMMON.NO") }}
          </span>
        </dd>
      </dl>
      <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)">
        <dt class="col-sm-4">{{ $t("COMMON.MANAGER") }}</dt>
        <dd class="col-sm-8">
          <users :users="location?.manager?.id ? [location.manager] : []" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          <div v-html="location.excerpt"></div>
        </dd>
      </dl>
      <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)">
        <dt class="col-sm-4">{{ $t("CONTACTS.CONTACTS") }}</dt>
        <dd class="col-sm-8">
          <contacts :contacts="location.contacts" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="location.organization" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="location.created_at">
          {{ $formatDate(location.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="location.updated_at">
          {{ $formatDate(location.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "location-view-global",

  components: {
    Users,
    Contacts,
  },

  props: ["location"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    location(location) {},
  },
};
</script>
