<template>
  <div>
    <span v-for="(user, idx) in users" :key="user.id">
      <router-link :to="$objectViewRoute(user)">
        <template>
          {{ `${user.firstname} ${user.lastname} - ${user.email}` }}
          {{ users.length - 1 > idx ? "," : " " }}<br />
        </template>
      </router-link>
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "users",

  components: {},

  mixins: [formMixin],

  props: {
    users: {
      type: Array,
      default: () => [],
      description: "Users",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    users(users) {},
  },
};
</script>
