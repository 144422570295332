<template>
  <el-select
    v-model="tagsModel"
    @change="tagChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.TAG')"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getTags"
    :loading="loading"
    :allow-create="allowCreate"
  >
    <el-option
      v-for="tag in tagsList"
      :key="tag.id"
      :value="tag.id"
      :label="`${tag.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { difference } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "tags-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: true,
      description: "Allow tag creation",
    },
    tags: {
      type: Array,
      default: () => [],
      description: "Tags",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    reseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      tagsModel: [...this.tags].map((item) => item.id),
      tagsList: {},
    };
  },

  setup() {},

  created() {
    this.getTags(null, this.tags);
  },

  methods: {
    async getTags(query = null, tags = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { name: query } : {}),
            ...(tags ? { ids: tags.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.organization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.organization,
            },
          };
        }

        await this.$store.dispatch("tags/list", params);
        const tagsArr = await this.$store.getters["tags/list"];
        this.tagsList = {};
        tagsArr.forEach((tag) => {
          this.tagsList[tag.id] = tag;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async tagChanged(tags) {
      const tagsData = [];
      for (let tag of tags) {
        if (!parseInt(tag)) {
          this.loading = true;
          await this.$store.dispatch("tags/add", {
            type: "tags",
            name: tag,
            relationshipNames: ["organization"],
            organization: {
              type: "organizations",
              id: this.organization,
            },
          });
          const tagObj = this.$store.getters["tags/tag"];
          tag = tagObj.id;
          this.loading = false;
        }
        tagsData.push(tag);
      }
      await this.getTags(null, tagsData);
      this.tagsModel = tagsData;
      this.$emit(
        "tagsChanged",
        [...tagsData].map((item) => ({ type: "tags", id: item }))
      );
    },
  },

  watch: {
    tags(tags) {
      if (tags) {
        const tagsData = tags.map((item) => item.id);
        if (difference(tagsData, this.tagsModel).length !== 0) {
          this.tagsModel = tagsData;
          this.getTags(null, tags);
        }
      } else {
        this.tagsModel = [];
      }
    },
    organization(organization) {
      this.getTags();
    },
  },
};
</script>
