<template>
  <modal :show="showModal" size="lg" modal-classes="elite-modal edit-file">
    <div class="elite-modal-form">
      <base-input
        class="form-wrapper full"
        v-model="fileModel.file_name"
        :label="`${$t('COMMON.NAME')}`"
        :placeholder="`${$t('COMMON.NAME')}`"
      >
      </base-input>

      <base-input
        class="form-wrapper full"
        :label="`${$t('COMMON.TAGS')}`"
        :placeholder="`${$t('COMMON.TAGS')}`"
      >
        <tags-selector
          :tags="fileModel.tags"
          @tagsChanged="
            (tags) => {
              fileModel.tags = tags;
            }
          "
          :organization="fileModel.organization.id"
        />
      </base-input>

      <!-- Excerpt -->
      <base-input
        class="form-wrapper full"
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="fileModel.excerpt"> </html-editor>
      </base-input>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn edit"
        @click="editFile"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.EDIT_FILE") }}
      </button>
      <button
        type="button"
        class="btn close ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import TagsSelector from "@/components/TagsSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    HtmlEditor,
    TagsSelector,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: Object,
      default: null,
      description: "File data",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/${this.object.type}/${this.object.id}/files`;
    },
  },

  watch: {},

  data() {
    return {
      loading: false,
      fileModel: cloneDeep(this.fileData),
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    async editFile() {
      this.loading = true;
      const fileData = cloneDeep(this.fileModel);
      fileData.name = `${fileData.file_name}.${fileData.file_extension}`;

      try {
        await this.$store.dispatch("files/update", fileData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        return;
      }

      this.$notify({
        type: "success",
        message: this.$t("FILES.FILE_UPDATED"),
      });
      this.closeModal(true);
      this.loading = false;
    },
  },

  watch: {
    fileData(fileData) {
      this.fileModel = cloneDeep(fileData);
    },
  },
};
</script>
