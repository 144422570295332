<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <organization-selector
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !filterObjectId
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />

        <base-input
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) &&
            !filterObjectId
          "
          :placeholder="`${$t('COMMON.LOCATIONS')}`"
        >
          <locations-selector
            @locationsChanged="(locations) => (selectedLocations = locations)"
          />
        </base-input>

        <base-input :placeholder="`${$t('COMMON.TAGS')}`">
          <tags-selector
            @tagsChanged="
              (tags) => {
                selectedTags = tags;
              }
            "
            :organization="selectedOrganization"
          />
        </base-input>

        <el-select
          v-if="!filterObjectId"
          v-model="selectedObjectType"
          :placeholder="$t('FILES.OBJECT')"
        >
          <el-option :value="null" :label="$t('FILES.ALL_OBJECTS')" />
          <el-option
            class="select-primary"
            v-for="item in models"
            :key="`${item.toLowerCase()}`"
            :label="$t(`COMMON.${item}`)"
            :value="`${item.toLowerCase()}`"
          >
          </el-option>
        </el-select>

        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="files"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <a :href="row.url" target="_blank">{{ row.name }}</a>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('FILES.SIZE')"
              prop="human_readable_size"
              sortable="custom"
              min-width="200"
            />
            <!-- <el-table-column :label="$t('COMMON.EXCERPT')" prop="excerpt">
          <template v-slot="{ row }">
            <div v-html="row.excerpt"></div>
          </template>
        </el-table-column> -->
            <el-table-column
              v-if="!filterObjectId"
              :label="$t('FILES.OBJECT')"
              prop="object"
              min-width="200"
            >
              <template v-slot="{ row }">
                <router-link
                  :to="$objectViewRoute(row.object)"
                  v-if="row.object"
                >
                  {{ $t(`COMMON.${row.object.type.toUpperCase()}`) }} <br />
                  {{ getObjectName(row.object) }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.TAGS')"
              prop="tags"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <tags :tags="row.tags" @tagsUpdated="getList" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.LOCATIONS')"
              sortable="custom"
              min-width="200"
              v-if="
                $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) &&
                !filterObjectId
              "
            >
              <template v-slot="{ row }">
                <locations :locations="row.allowedLocations" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              sortable="custom"
              min-width="200"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                !filterObjectId
              "
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="200"
            />
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_FILES)"
                >
                  <a
                    type="text"
                    @click="viewFile(row.id)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>

                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_FILES)"
                >
                  <a
                    type="text"
                    @click="editFile(row.id)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>

                <el-tooltip
                  content="Delete"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_FILES)"
                >
                  <a
                    type="text"
                    @click="deleteFile(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import Tags from "@/components/Tags.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "file-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    Tags,
    TagsSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterObjectType: {
      type: String,
      default: null,
      description: "Object class",
    },
    filterObjectId: {
      type: String,
      default: null,
      description: "Object id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      files: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedObjectType: null,
      selectedTags: [],
      models: models,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTags: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterObjectType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedObjectType: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterObjectId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,tags,allowedLocations,object",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedTags) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              tags: this.selectedTags.map((item) => item.id),
            },
          };
        }
        if (this.filterObjectType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectType: this.filterObjectType,
            },
          };
        }
        if (this.selectedObjectType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectType: this.selectedObjectType,
            },
          };
        }
        if (this.filterObjectId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectId: this.filterObjectId,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("files/list", params);
        this.files = this.$store.getters["files/list"];
        this.total = this.$store.getters["files/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteFile(id) {
      this.$emit("onDeleteFile", id);
    },

    async viewFile(id) {
      this.$emit("onViewFile", id);
    },

    async editFile(id) {
      this.$emit("onEditFile", id);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    getObjectName(object) {
      if (object.customer_name) {
        return object.customer_name;
      }
      if (object.name) {
        return object.name;
      }
      if (object.company_name) {
        return object.company_name;
      }
      if (object.code) {
        return object.code;
      }
      return this.$t(`COMMON.${object.type.toUpperCase()}`);
    },
  },
};
</script>
