<template>
  <div>
    <span
      class="btn btn-sm"
      :style="[{ 'background-color': tag.color, color: '#ffffff' }]"
      v-for="tag in tags"
      :key="tag.id"
      @click="editTag(tag)"
    >
      <span :disabled="loading" class="btn-inner--icon">
        <i class="fa fa-tags"></i>
      </span>
      {{ tag.name }}
    </span>

    <modal :show="showModal" modal-classes="modal-secondary tag-modal">
      <form class="" @submit.prevent="">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :placeholder="$t('COMMON.NAME')"
          v-model="tagModel.name"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />

        <base-input
          :label="`${$t('COMMON.COLOR')} (*)`"
          :placeholder="$t('COMMON.COLOR')"
          v-model="tagModel.color"
          input-classes="form-control-alternative"
          type="color"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.color" />
      </form>

      <template slot="footer">
        <button
          type="button"
          class="btn btn-elite black"
          @click="closeModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>

        <base-button
          native-type="submit"
          type="button"
          class="btn ml-auto btn-elite save"
          @click="saveTag"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.UPDATE") }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "tags",

  components: { ValidationError },

  mixins: [formMixin],

  props: {
    tags: {
      type: Array,
      default: () => [],
      description: "Tags",
    },
  },

  data() {
    return {
      tagModel: {
        type: "tags",
        id: null,
        name: null,
        color: null,
      },
      showModal: false,
      loading: false,
    };
  },

  setup() {},

  created() {},

  methods: {
    editTag(tag) {
      this.tagModel = {
        type: "tags",
        id: tag.id,
        name: tag.name,
        color: tag.color,
      };
      this.showModal = true;
    },
    async saveTag() {
      try {
        await this.$store.dispatch("tags/update", this.tagModel);
        this.$notify({
          type: "success",
          message: this.$t("COMMON.UPDATED"),
        });
        this.$emit("tagsUpdated", true);
        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    closeModal() {
      this.showModal = false;
      this.tagModel = {
        type: "tags",
        id: null,
        name: null,
        color: null,
      };
    },
  },

  watch: {
    tags(tags) {},
  },
};
</script>

<style lang="scss">
.tag-modal {
  .modal-content {
    .modal-body {
      form {
        span {
          display: block;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .form-group {
            margin-bottom: 0;
            .form-control-label {
              margin: 0 0 10px 0;
            }
            input[type="color"] {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
</style>
