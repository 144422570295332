<template>
  <div class="page-wrapper-table-body">
    <div class="page-wrapper-table-body-inner">
      <el-table
        class="align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="users"
      >
        <div class="table-loading" slot="empty" v-if="loading">
          <img src="/img/loading.gif" />
        </div>
        <el-table-column
          :label="$t('COMMON.NAME')"
          prop="firstname"
          min-width="220"
        >
          <template v-slot="{ row }">
            <span>{{ `${row.firstname} ${row.lastname}` }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.EMAIL')"
          prop="email"
          min-width="220"
        />
        <el-table-column
          :label="$t('COMMON.PHONE')"
          prop="phone"
          min-width="220"
        />
        <el-table-column min-width="120">
          <div slot-scope="{ row }" class="table-actions">
            <!--
<el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
            >
              <a
                  type="text"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
              >
                <i class="fal fa-expand-alt"></i>
              </a>
            </el-tooltip>
<el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_USERS)"
            >
              <a
                  type="text"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
              >
                <i class="fal fa-edit"></i>
              </a>
            </el-tooltip>
-->
            <el-tooltip
              :content="$t('USERS.DETACH')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_DELETE_USERS)"
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
                @click="onSubmitUserDetach(row)"
              >
                <i class="fal fa-trash-alt"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";

export default {
  name: "location-users-view-table",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterLocation: {
      type: String,
      default: null,
      description: "Location id",
    },
    get: {
      type: Function,
    },
  },

  data() {
    return {
      users: [],
      loading: true,
      total: 0,
      selectedRows: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
    };
  },

  setup() {},

  mounted() {
    this.getUsers();
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  methods: {
    async onUserDetach(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("locations/detachUsers", {
          id: this.filterLocation,
          users: [id],
        });
        this.$notify({
          type: "success",
          message: this.$t("LOCATIONS.LOCATIONS_UPDATED"),
        });
        this.get();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async onSubmitUserDetach(user) {
      const confirmation = await swal.fire({
        title: this.$t("USERS.REMOVE_THIS_USER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.onUserDetach(user?.id);
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async getUsers() {
      try {
        this.loading = true;
        let params = {
          sort: "firstname",
          filter: {},
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.filterLocation) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: [this.filterLocation],
            },
          };
        }
        await this.$store.dispatch("users/list", params);
        this.users = await this.$store.getters["users/list"];
        this.total = this.$store.getters["users/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  watch: {
    filterOrganization(filterOrganization) {
      this.getUsers();
    },

    pagination: {
      handler: "getUsers",
      immediate: false,
      deep: true,
    },
  },
};
</script>

<style scoped></style>
